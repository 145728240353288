import type { PaginationData } from 'egenie-utils';
import { request, ImgFormatter } from 'egenie-utils';
import React from 'react';
import type { SubItem } from './types';

export function detailGrid() {
  return {
    tab: {
      name: 'SKU明细',
      value: 'detail',
    },
    grid: {
      columns: [
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
          width: 200,
        },
        {
          key: 'barCode',
          name: '条形码',
          width: 200,
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺码',
          width: 100,
        },
        {
          key: 'costPrice',
          name: '成本价',
          width: 100,
        },
        {
          key: 'weight',
          name: '重量',
          width: 100,
        },
        {
          key: 'tagNames',
          name: '标签',
          width: 200,
        },
        {
          name: '服装类别',
          key: 'cottonDesc',
          width: 80,
        },
        {
          key: 'enabled',
          name: '是否启用',
          width: 80,
          formatter: ({ row }) => (
            <span>
              {row.enabled ? '是' : '否'}
            </span>
          ),
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'skuId',
      sortByLocal: false,
      showCheckBox: false,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/goodsInformation/index/sub',
    },
    api: {
      onQuery: ({
        data,
        pid,
      }) => {
        const {
          sidx,
          sord,
          page,
          pageSize,
        } = data;
        return request<PaginationData<SubItem[]>>({
          url: '/api/cloud/baseinfo/rest/sku/list/page',
          method: 'POST',
          data: {
            productId: pid,
            sidx,
            sord,
            page,
            pageSize,
          },
        });
      },
    },
  };
}
