import { Programme, ProgrammeComponent } from 'egenie-utils';
import React from 'react';
import { getAllVendor, getOwner, getTagList } from '../../utils';
import { filterItems } from './filterItems';
import { mainSubStructureModel } from './mainSubStructureModel';
import { ChargeModal } from './chargeModal';
import { destroyAllModal, renderModal, request } from 'egenie-common';
import { message } from 'antd';

export class Store {
  constructor() {
    getOwner()
      .then((ownerIds) => this.programme.filterItems.addDict({ ownerIds }));
    getAllVendor()
      .then((vendorShopIds) => this.programme.filterItems.addDict({ vendorShopIds }));
    getTagList()
      .then((tagIds) => this.programme.filterItems.addDict({ tagIds }));
  }

  public mainSubStructureModel = mainSubStructureModel(this);

  public programme: Programme = new Programme({
    gridModel: this.mainSubStructureModel,
    filterItems: filterItems(this),
    moduleName: 'egenieCloudWmsGoodsInformationIndex',
  });

  public handleSettingCharge = () => {
    const productIds = Array.from(this.mainSubStructureModel.gridModel.selectedIds);
    if (productIds.length === 0) {
      const error = '请至少选择一条数据';
      message.warning({
        key: error,
        content: error,
      });
      return;
    }

    renderModal(
      <ChargeModal
        callback={(params) => request({
          url: '/api/cloud/baseinfo/rest/product/bill/update',
          method: 'POST',
          data: {
            notifyBill: params.notifyBill,
            productIds,
          },
        })
          .then(() => {
            message.success('设置成功');
            destroyAllModal();
            this.mainSubStructureModel.gridModel.clearToOriginal();
            this.mainSubStructureModel.onRefresh();
          })}
        onCancel={destroyAllModal}
      />
    );
  };
}

const store = new Store();

export default function() {
  return <ProgrammeComponent store={store.programme}/>;
}
