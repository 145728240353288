export const ENUM_DELIVER_CHARGING_TYPE = {
  charge: {
    value: '1',
    label: '计费',
  },
  notCharge: {
    value: '0',
    label: '不计费',
  },
};
