import { Form, Modal, Radio } from 'antd';
import React from 'react';
import { ENUM_DELIVER_CHARGING_TYPE } from './constants';

interface ChargeModalProps {
  callback: (params: { notifyBill: string; }) => Promise<unknown>;
  onCancel: (...args: any[]) => any;
}

export function ChargeModal({
  callback,
  onCancel,
}: ChargeModalProps) {
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState({
    loading: false,
    params: null,
  });
  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      callback(paramData.params)
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Modal
      centered
      maskClosable={false}
      okButtonProps={{ loading: paramData.loading }}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      title="设置计费"
      visible
      width={400}
    >
      <Form
        form={form}
        initialValues={{ notifyBill: ENUM_DELIVER_CHARGING_TYPE.charge.value }}
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          name="notifyBill"
          rules={[
            {
              required: true,
              message: '请选择计费方式',
            },
          ]}
        >
          <Radio.Group options={Object.values(ENUM_DELIVER_CHARGING_TYPE)}/>
        </Form.Item>
      </Form>
    </Modal>
  );
}
