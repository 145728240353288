import type { PaginationData } from 'egenie-utils';
import { ImgFormatter, MainSubStructureModel, request, TimeStampFormatter } from 'egenie-utils';
import React from 'react';
import { detailGrid } from './detailGrid';
import type { MainItem } from './types';
import type { Store } from './index';

export function mainSubStructureModel(content: Store) {
  return new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'productName',
          name: '商品名称',
          width: 250,
        },
        {
          key: 'productNo',
          name: '商品编码',
          width: 250,
        },
        {
          key: 'vendorName',
          name: '供应商名称',
          width: 200,
        },
        {
          key: 'sellerOuterNo',
          name: '商品货号',
          width: 200,
        },
        {
          key: 'ownerName',
          name: '货主',
          width: 200,
        },
        {
          key: 'costPrice',
          name: '成本价',
          width: 80,
        },
        {
          key: 'createTime',
          name: '创建时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.createTime}/>,
        },
        {
          key: 'lastUpdateTime',
          name: '更新时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.lastUpdateTime}/>,
        },
        {
          key: 'hasPrinting',
          name: '印花款',
          width: 80,
          formatter: ({ row }) => (
            <span>
              {row.hasPrinting ? '是' : '否'}
            </span>
          ),
        },
        {
          key: 'notifyBill',
          name: '发货计费',
          width: 80,
          formatter: ({ row }) => (
            <span>
              {row.notifyBill ? '计费' : '不计费'}
            </span>
          ),
        },
        {
          key: 'enabled',
          name: '是否启用',
          width: 80,
          formatter: ({ row }) => (
            <span>
              {row.enabled ? '是' : '否'}
            </span>
          ),
        },
        {
          key: 'authStyle',
          name: '认证款',
          width: 80,
          formatter: ({ row }) => (
            <span>
              {row.authStyle ? '是' : '否'}
            </span>
          ),
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'productId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 200,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/goodsInformation/index/main',
    },
    pageId: '60087',
    buttons: [
      {
        permissionId: '6032',
        text: '设置计费',
        handleClick: () => content.handleSettingCharge(),
      },
    ],

    // 主表查询api
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<MainItem>>({
          url: '/api/cloud/baseinfo/rest/product/list/page',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    subTables: {
      activeTab: 'detail',
      tabsFlag: {
        inited: {
          detail: true,
          log: false,
        },
        searched: {},
      },
      list: [detailGrid()],
    },
  });
}
