import type { FilterItemOptions } from 'egenie-utils';
import type { Store } from './index';
import { ENUM_CLOTHES_MATERIAL_TYPE } from '../qualityTesting/constants';
import { ENUM_DELIVER_CHARGING_TYPE } from './constants';

export function filterItems(context: Store): FilterItemOptions[] {
  return [
    {
      type: 'date',
      field: 'date',
      label: '日期类型',
      selectValue: 'createTime',
      data: [
        {
          value: 'createTime',
          label: '创建时间',
        },
      ],
    },
    {
      type: 'input',
      field: 'skuNo',
      label: 'SKU编码',
    },
    {
      type: 'input',
      field: 'productNo',
      label: '商品编码',
    },
    {
      type: 'select',
      mode: 'multiple',
      field: 'ownerIds',
      label: '货主',
    },
    {
      type: 'select',
      mode: 'multiple',
      field: 'vendorShopIds',
      label: '供应商',
    },
    {
      type: 'select',
      field: 'hasWeight',
      label: '重量',
      data: [
        {
          value: '1',
          label: '有重量',
        },
        {
          value: '0',
          label: '无重量',
        },
      ],
    },
    {
      type: 'select',
      field: 'hasPrinting',
      label: '印花款',
      data: [
        {
          value: '1',
          label: '是',
        },
        {
          value: '0',
          label: '否',
        },
      ],
    },
    {
      type: 'select',
      mode: 'multiple',
      field: 'tagIds',
      label: '标签',
    },
    {
      type: 'select',
      field: 'cotton',
      label: '服装类别',
      data: Object.values(ENUM_CLOTHES_MATERIAL_TYPE).map((item) => ({
        value: `${item.value }`,
        label: item.label,
      })),
    },
    {
      type: 'select',
      field: 'notifyBill',
      label: '发货计费',
      data: Object.values(ENUM_DELIVER_CHARGING_TYPE),
    },
    {
      type: 'select',
      field: 'authStyle',
      label: '认证款',
      data: [
        {
          value: '1',
          label: '是',
        },
        {
          value: '0',
          label: '否',
        },
      ],
    },
  ];
}
